import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

import CalltoAction from "../components/CalltoAction";
// import PictureGrid from "../components/PictureGrid";
// import PageIntro from "../components/PageIntro";
// import AlternatingRowsContent from "../components/AlternatingRows";

import $ from "jquery/dist/jquery";

/* const ImageArray = [
    { path: "../../window-cl-pr1.png", alt: "window-cl-pr1" },
    { path: "../../window-cl-pr2.png", alt: "window-cl-pr2" },
    { path: "../../window-cl-pr3.png", alt: "window-cl-pr3" },
    { path: "../../window-cl-pr4.png", alt: "window-cl-pr4" }
]; */

class Sanitiser extends React.Component {
  componentDidMount() {
    var $menu = $(".main-menu ul").clone();
    $("#mobile-menu").html($menu);
    $("#mobile-menu ul li:last-child").remove();

    $(".mobile-menu-icon").on("click", function() {
      $(".mobile-menu-wrap").toggleClass("mobile-menu-show");
    });

    $(".close-btn").on("click", function() {
      $(".mobile-menu-wrap").removeClass("mobile-menu-show");
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="google-site-verification"
            content="YAjmKXP8olHAA_JpbqTrbgHbFFvG4h8yvrpmb1lzL1w"
          />
          <title>
            Order hand sanitiser in Castlebar, Ireland from GG Cleaning Ireland
          </title>
          <description>
            Contact GG Cleaning to order a delivery of hand sanitiser to your
            home or office: info@ggcleaning.ie
          </description>
        </Helmet>

        <div>
          {/* Start header section */}
          <section className="header-section">
            <a href="/contact" className="reqBtn _btn-brand _btn">
              Request a quote
            </a>
            <div className="d-flex align-items-center">
              <div className="col-lg-12 header-left order-lg-1 order-md-2 order-2 pad0">
                <div className="home-banner serviceBanner sanitizerBanner half-height-banner">
                  <div className="home-title">
                    <h2>Order Hand Sanitiser</h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End header section */}

          <br />
          <br />
          <br />
          <br />

          <h3 style={{ textAlign: `center` }}>
            Visit our brand new shop to purchase your sanitiser products
          </h3>
          <br />
          <h4 style={{ textAlign: `center` }}>
            <a href="/contact">
              Buy Cleaning & Hand Sanitiser Products
            </a>
          </h4>

          <br />
          <br />
          <br />

          {/* Start ImageGallery section */}
          {/* <div>
                        <section className="section-gap project-area">
                            <div className="projectTitle service-page-content">
                                <div className="container">
                                    <h4>Carpet Cleaning Projectjects</h4>
                                    <a href="/projects" className="view-all-btn">
                                        View All <img src="../../arrow.png" alt="arrow" />
                                    </a>
                                </div>
                            </div>
                            <div className="project-list">
                                <PictureGrid Images={ImageArray} />
                            </div>
                        </section>
                    </div> */}
          {/* End ImageGallery section */}
          {/* Start request section */}
          <CalltoAction
            bgimage="../../footer-baner.png"
            btnbgcolor="#D91F3D"
            btncolor="#fff"
            heading={"Let us help your business shine"}
            action="/contact"
            btnval="Request a quote"
          />
          {/* End request section */}
        </div>
      </Layout>
    );
  }
}
export default Sanitiser;
